import React from "react";

function HamburgerIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5 15H25" stroke="#122843" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 7.5H25" stroke="#122843" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 22.5H25" stroke="#122843" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default HamburgerIcon;
