import React, { useEffect } from "react";

import clsx from "clsx";
import { Link, navigate } from "gatsby";

import { navs } from "~/src/constants/navs";
import { useContextUpdate, useContextValue } from "~/src/context";

// TODO: must-enforce logged in state

const AppSidebar = () => {
  const { rehydrated, isLoggedIn, medic } = useContextValue();
  const { user } = useContextValue();
  const { destroySession } = useContextUpdate();

  useEffect(() => {
    if (rehydrated && !isLoggedIn) {
      navigate("/");
    }
  }, [rehydrated, isLoggedIn]);

  return (
    <div className="hidden md:block h-screen bg-gray-100 w-[150px] border-r-[1px] border-gray-200 px-1">
      <div className="w-full py-2 md:py-4 bg-gray-100 rounded-md flex flex-col">
        <div className="rounded-md bg-blue-500 text-white flex items-center justify-center h-[40px] w-[40px]">
          {user?.fullname.split(" ")[0][0] || ""}
          {user?.fullname.split(" ")[1][0] || ""}
        </div>
        <div className="pl-[3px] mt-1">
          <div>{user?.fullname || ""}</div>
          <div className="text-examedi-gray-strong text-xs">{medic?.title || ""}</div>
          <div className="text-examedi-gray-strong text-xs">{medic?.subtitle || ""}</div>
        </div>
      </div>
      <div className="mt-12">
        {navs.map((item: any, i: number) => (
          <Link to={item.link} key={i}>
            <div
              key={i}
              className={clsx(
                "w-full",
                "py-3",
                "rounded-md",
                "hover:cursor-pointer",
                "hover:bg-gray-200",
                "mt-[3px]",
                "text-sm",
              )}
            >
              {item.text}
            </div>
          </Link>
        ))}
      </div>

      <div
        className="mt-24 w-full py-3 text-sm hover:bg-gray-200 hover:cursor-pointer rounded-md"
        onClick={() => {
          destroySession();
          navigate("/");
        }}
      >
        Cerrar sesión
      </div>
    </div>
  );
};

export default AppSidebar;
