import React from "react";

import clsx from "clsx";
import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { navs } from "~/src/constants/navs";
import { useContextUpdate } from "~/src/context";

const MobileNav = ({ closeNav }: { closeNav: () => void }) => {
  const { destroySession } = useContextUpdate();

  return (
    <div className="w-full h-screen fixed bg-white z-10 top-0">
      <div className="bg-gradient-to-r from-[#607CEE] to-[#FF7575] w-full py-[10px] px-[20px] text-xs text-white">
        ¿Necesitas alguna herramienta más? <strong>Contáctanos, la creamos por tí</strong>
      </div>
      <div className="bg-white border-b-[1px] border-gray-200 h-[75px] w-full px-[20px] flex items-center justify-between">
        <Link to="/">
          <StaticImage
            className={clsx("my-auto")}
            placeholder="tracedSVG"
            src="../images/brand/examedi-logo.png"
            alt="Logo Examedi"
            style={{ height: "22px", width: "137.5px" }}
          />
        </Link>
        <div
          className="font-bold hover:text-red-400 hover:cursor-pointer"
          onClick={() => {
            closeNav();
          }}
        >
          X
        </div>
      </div>
      <div>
        {navs.map((item: any, i: number) => (
          <Link
            to={item.link}
            key={i}
            onClick={() => {
              closeNav();
            }}
          >
            <div
              key={i}
              className={clsx(
                "w-full",
                "py-3",
                "px-4",
                "rounded-md",
                "hover:cursor-pointer",
                "hover:bg-gray-200",
                "mt-[3px]",
                "text-sm",
              )}
            >
              {item.text}
            </div>
          </Link>
        ))}
        <div
          className="mt-24 w-full py-3 px-4 text-sm hover:bg-gray-200 hover:cursor-pointer rounded-md"
          onClick={() => {
            destroySession();
            navigate("/");
          }}
        >
          Cerrar sesión
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
