import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";

import { getMedicProfile } from "~/src/api-client/medics";
import DashboardLayout from "~/src/components/DashboardLayout";
import { useContextUpdate, useContextValue } from "~/src/context";
import LoadingSpinnerIcon from "~/src/icons/LoadingSpinnerIcon";

const AppLanding = () => {
  const { medic, session, rehydrated } = useContextValue();
  const { setMedicKeys } = useContextUpdate();

  const [updating, setUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (rehydrated) {
      if (!session) {
        navigate("/");
      } else if (medic && medic.active) {
        navigate("/app/profile/");
      }
    }
  }, [rehydrated, session, medic]);

  const requestMedicProfile = async () => {
    const res = await getMedicProfile(session?.accessToken || "");
    if (res) {
      setMedicKeys(res.data);
      if (!res.data.active) {
        // eslint-disable-next-line no-alert
        alert("Aún no has sido activado");
      }
    }
  };

  return (
    <DashboardLayout>
      <div
        className={clsx(
          "relative",
          "bg-white",
          "w-[100%]",
          "h-[150px]",
          "py-5",
          "rounded-lg",
          "border-gray-200",
          "drop-shadow-md",
          "flex flex-col items-center justify-around",
        )}
      >
        <span>Tu cuenta debe ser autorizada por un administrador para utilizar la plataforma.</span>
        <button
          className={clsx(
            "px-6",
            "py-2",
            "text-white",
            "rounded-full",
            "bg-blue-700 hover:bg-blue-500",
            "text-xs md:text-sm",
          )}
          onClick={() => {
            requestMedicProfile();
          }}
          disabled={updating}
        >
          {updating ? <LoadingSpinnerIcon /> : "Ya me activaron"}
        </button>
      </div>
    </DashboardLayout>
  );
};

export default AppLanding;
