import { Medic, MedicPayload } from "../types/medic";

import client from "~/src/api-client";
import namespaced from "~/src/utils/debug";

const debug = namespaced("api-client/medics");

export async function getMedicProfile(token: string): Promise<MedicPayload | undefined> {
  try {
    const res = await client.get<MedicPayload>("/medic-referrals/medic/medic_profile/", {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[getMedicProfile] response was ${res.status}`, res);
    return res?.data;
  } catch (err) {
    debug(["getMedicProfile"], err);
    return undefined;
  }
}

export async function updateProfile(
  token: string,
  data: Medic | Record<string, unknown>,
): Promise<MedicPayload | undefined> {
  try {
    const res = await client.put<MedicPayload>("/medic-referrals/medic/update_profile/", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[updateMedicProfile] response was ${res.status}`, res);
    return res?.data;
  } catch (err) {
    debug(["updateMedicProfile"], err);
    return undefined;
  }
}

export async function createMedicProfileRequest(token: string): Promise<MedicPayload | undefined> {
  try {
    const res = await client.post<MedicPayload>(
      "/medic-referrals/medic/create_request/",
      { title: "", address: "" },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    debug(`[createMedicProfileRequest] response was ${res.status}`, res);
    return res?.status === 200 ? res?.data : undefined;
  } catch (err) {
    debug(["createMedicProfileRequest"], err);
    return undefined;
  }
}

export async function uploadMedicSignature(token: string, files: Array<File>): Promise<MedicPayload | undefined> {
  try {
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }
    const res = await client.post<MedicPayload>("/medic-referrals/upload_signature/", formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[createMedicProfileRequest] response was ${res.status}`, res);
    return res?.status === 200 ? res?.data : undefined;
  } catch (err) {
    debug(["createMedicProfileRequest"], err);
    return undefined;
  }
}
