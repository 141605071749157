import React, { useState } from "react";

import "~/src/styles/global.css";
import clsx from "clsx";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import HamburgerIcon from "../icons/HamburgerIcon";
import LoadingSpinnerIcon from "../icons/LoadingSpinnerIcon";
import MobileNav from "./AppNavigation/MobileNav";
import AppSidebar from "./AppNavigation/sidebar";

import SEO from "~/src/components/SEO";
import { useContextValue } from "~/src/context";

type DashboardLayoutProps = {
  children: React.ReactNode;
  header?: React.ReactNode;
  childrenClassName?: string;
  preHeader?: React.ReactNode;
  containerClassName?: string;
};

const DashboardLayout = ({
  children,
  header,
  childrenClassName,
  preHeader,
  containerClassName,
}: DashboardLayoutProps) => {
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const { preLoading } = useContextValue();
  return (
    <React.Fragment>
      <SEO title="Examedi | Mi Portal" />
      {/* <div className="bg-gradient-to-r from-[#607CEE] to-[#FF7575] w-full py-[10px] px-[20px] text-xs text-white">
        ¿Necesitas alguna herramienta más? <strong>Contáctanos, la creamos por tí</strong>
      </div> */}
      <div className="bg-white border-b-[1px] border-gray-200 h-[75px] w-full px-[20px] flex items-center justify-between">
        <Link to="/">
          <StaticImage
            className={clsx("my-auto")}
            placeholder="tracedSVG"
            src="../images/brand/examedi-logo.png"
            alt="Logo Examedi"
            style={{ height: "22px", width: "137.5px" }}
          />
        </Link>
        <HamburgerIcon
          className="hover:cursor-pointer md:hidden"
          onClick={() => {
            setShowMobileNav(true);
          }}
        />
      </div>
      <div className="min-h-screen min-w-full flex">
        <AppSidebar />
        {showMobileNav && (
          <MobileNav
            closeNav={() => {
              setShowMobileNav(false);
            }}
          />
        )}

        <div className={clsx("w-full min-h-screen relative", containerClassName)}>
          {preLoading && (
            <div className="w-full min-h-screen z-50 opacity-50 bg-gray-200 flex items-center justify-center absolute top-0 left-0">
              <LoadingSpinnerIcon className="h-[100px] w-[100px]" />
            </div>
          )}

          {preHeader}
          {header}
          <div className={clsx("p-[5px] md:py-[20px] text-examedi-black-dark", childrenClassName)}>{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardLayout;
